

angular.module('app')




		.factory('InfoMessageService', [function InfoMessageServiceFactory()
		{
			// local object to keep track of which info-messages have shown
			var shownInfoMessages = {};


			/**
			 * use this method to determine whether an info-message has shown yet
			 * info-messages are one-time UI pop-ups or hints to orient the user
			 *
			 * @see markInfoMessageAsShown()
			 *
			 * @param infoMessageName
			 * @return {boolean}
			 */
			function infoMessageHasShown(infoMessageName)
			{
				if (shownInfoMessages[infoMessageName] === true)
				{
					return true;
				}
				else
				{
					shownInfoMessages[infoMessageName] = false;
					return false;
				}
			}


			/**
			 * use this method to mark an info-message as shown
			 *
			 * @see infoMessageHasShown()
			 *
			 * @param infoMessageName		string
			 * @return void
			 */
			function markInfoMessageAsShown(infoMessageName)
			{
				shownInfoMessages[infoMessageName] = true;
			}


			return {
				infoMessageHasShown: infoMessageHasShown,
				infoMessageWasShown: markInfoMessageAsShown
			};
		}])

;
