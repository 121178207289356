

angular.module('app')


	.run(function ()
	{
		if ('addEventListener' in document)
		{
			document.addEventListener('DOMContentLoaded', function()
			{
				FastClick.attach(document.body);
			}, false);
		}
	})



	.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function ($stateProvider, $urlRouterProvider, $locationProvider)
	{
		$locationProvider.html5Mode(true);

		$stateProvider

			// ------------------------------ templates ------------------------------

			.state('app', {
				url: '',
				abstract: true,
				templateUrl: 'app/templates/app.html',
				controller: 'AppController as app'
			})

			// ------------------------------ pages ------------------------------

			.state('test', {
				url: '/test',
				templateUrl: 'app/pages/test/test.html',
				controller: 'TestController as test'
			})

			.state('welcome', {
				url: '/',
				templateUrl: 'app/pages/welcome/welcome.html',
				controller: 'WelcomeController as welcome'
			})

			.state('app.home', {
				url: '/home',
				templateUrl: 'app/pages/home/home.html',
				controller: 'HomeController as home'
			})

			.state('app.contact', {
				url: '/contact',
				templateUrl: 'app/pages/contact/contact.html',
				controller: 'ContactController as contact'
			})

			.state('app.about', {
				url: '/about',
				templateUrl: 'app/pages/about/about.html',
				controller: 'AboutController as about'
			})

			.state('app.product_list', {
				url: '/products',
				templateUrl: 'app/pages/product_list/product_list.html',
				controller: 'ProductListController as productList'
			})

			.state('app.product_detail', {
				url: '/products/:id',
				templateUrl: 'app/pages/product_detail/product_detail.html',
				controller: 'ProductDetailController as productDetail'
			})

		;


		$urlRouterProvider.otherwise('/');


	}])



;





