

angular.module('app')




		.factory('TestService', ['$http', '$q', function TestServiceFactory($http, $q)
		{


			function getValue()
			{
				var testTask = $q.defer();
				var formToSubmit = {};
				formToSubmit['_csrf'] = $('body').data('token');

				$http.post('/api/v1/test', formToSubmit)
					.success(function (result, status, headers, config)
					{
						if (result.success)
						{
							testTask.resolve(result.message);
						}
						else
						{
							testTask.reject(result.message);
						}
					})
					.error(function (result, status, headers, config)
					{
						testTask.reject('Could not send - there may be a problem with the connection.');
					});


				return testTask.promise;
			}



			function getSanitizedInputValues()
			{
				return {};
				/*
				var messageValue = ($homeContactMessage.val() == defaultContactValues['message']) ? '' : $homeContactMessage.val().replace(/[^a-zA-Z0-9!@&',\. _-]/g, '');
				return {
					name: $homeContactName.val().replace(/[^a-zA-Z0-9!@&',\. _-]/g, ''),
					email: $homeContactEmail.val().replace(/[^a-zA-Z0-9!@&',\. _-]/g, ''),
					message: messageValue
				};
				*/
			}


			return {
				getValue: getValue
			};
		}])

;
