angular.module('app')

	.controller('ProductListController', ['$scope', '$state', 'PageService', function ProductListController ($scope, $state, PageService)
	{
		var productList = this;
		var productListPage = document.getElementById('product_list_page');

		productList.navToProductDetails = function (productId)
		{
			PageService.navToPage('app.product_detail', {id: productId});
		};
	}]);
