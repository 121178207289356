

angular.module('app')




		.factory('PageService', ['$rootScope', '$timeout', '$state', function PageServiceFactory($rootScope, $timeout, $state)
		{
			var currentPage;
			var oldPage;



			$rootScope.$on('pageIsHidden', function (event, args)
			{
				$state.go(currentPage);

				$rootScope.$broadcast('showPage', {
					currentPage: currentPage,
					oldPage: oldPage
				});

				$rootScope.$broadcast('pageChanged::' + currentPage, {
					currentPage: currentPage,
					oldPage: oldPage
				});
			});


			function navToPage(newPage)
			{
				oldPage = currentPage;
				currentPage = newPage;

				$rootScope.$broadcast('hidePage');
			}


			function getCurrentPage()
			{
				return currentPage;
			}


			function getOldPage()
			{
				return oldPage;
			}


			function showPage()
			{
				$rootScope.$broadcast('showPage', {
					currentPage: currentPage,
					oldPage: oldPage
				});
			}




			return {
				getCurrentPage: getCurrentPage,
				getOldPage: getOldPage,
				navToPage: navToPage,
				showPage: showPage
			};
		}])

;
