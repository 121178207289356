var appPage, appMenu;




angular.module('app')

		.controller('AppController', ['$scope', '$state', 'PageService', function AppController ($scope, $state, PageService)
		{
			var app = this;

			appPage = document.getElementById('app_page');
			appMenu = document.getElementById('app_menu');


			// show for the first time
			showAppPage();

			registerPageEntryHandler();

			app.navToPage = function (page)
			{
				PageService.navToPage(page);
			};


			var showingMenu = true;
			app.toggleMenu = function ()
			{
				(showingMenu) ? closeMenu() : showMenu();
				showingMenu = ! showingMenu;
			};


			function registerPageEntryHandler()
			{
				$scope.$on('hidePage', function (event, args)
				{
					hideAppPage(function()
					{
						$scope.$emit('pageIsHidden');
					});
				});

				$scope.$on('showPage', function (event, args)
				{
					showAppPage();
				});
			}
		}])


;




function showAppPage()
{
	var mySequence = [
		{ e: appPage, p: { opacity: 0 }, o: { duration: 0 } },

		{ e: appPage, p: { opacity: 1 }, o: { duration: 1500 } }
	];

	$.Velocity.RunSequence(mySequence);
}



function hideAppPage(doneFn)
{
	var mySequence = [
		{ e: appPage, p: { opacity: 0 }, o: { duration: 1000, complete: doneFn } }
	];

	$.Velocity.RunSequence(mySequence);
}



function showMenu()
{
	var mySequence = [
		{ e: appMenu, p: { opacity: 1, translateX: 0 }, o: { duration: 500 } }
	];

	$.Velocity.RunSequence(mySequence);
}


function closeMenu()
{
	var mySequence = [
		{ e: appMenu, p: { opacity: 0, translateX: '-100%' }, o: { duration: 500 } }
	];

	$.Velocity.RunSequence(mySequence);
}


