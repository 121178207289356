angular.module('app')

	.controller('TestController', ['$scope', '$timeout', 'TestService', function TestController ($scope, $timeout, TestService)
	{
		var test = this;
		var testPage = document.getElementById('test_page');


		test.getResult = function() {

			TestService.getValue().then(function(message) {
				alert(message);
			});

		};

	}]);
